
import { defineComponent, onMounted, reactive, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import mAxiosApi from "@/api";
import { MenuComponent } from "@/assets/ts/components";
import { ElNotification } from "element-plus";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "account-settings",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const changeDatas = ref(false);
    const changeStorage = ref(false);
    const changeIdentifieurs = ref(false);
    const applychanges = ref<HTMLElement | null>(null);
    const applystorage = ref<HTMLElement | null>(null);
    const applychangesidentifieurs = ref<HTMLElement | null>(null);

    const detailsValidator = Yup.object().shape({
      identifiant:Yup.string().nullable().label("identifiant"),
      codeserv: Yup.string().nullable().label("codeserv").required('Le champ est obligatoire'),
      nameserv: Yup.string().nullable().label("nameserv").required('Le champ est obligatoire'),
      pays: Yup.string().nullable().label("pays").required('Le champ est obligatoire'),
      numdepart: Yup.string().nullable().label("numdepart").required('Le champ est obligatoire'),
      nomdepart: Yup.string().nullable().label("nomdepart").required('Le champ est obligatoire'),
      ville: Yup.string().nullable().label("ville").required('Le champ est obligatoire'),
      region: Yup.string().nullable().label("region").required('Le champ est obligatoire'),
      nameres: Yup.string().nullable().label("nameres").required('Le champ est obligatoire'),
      titreres: Yup.string().nullable().label("titreres").required('Le champ est obligatoire'),
      juridique: Yup.string().nullable().label("juridique").required('Le champ est obligatoire'),
      publisher: Yup.string().nullable().label("publisher").required('Le champ est obligatoire'),
      adressline: Yup.string().nullable().label("adressline").required('Le champ est obligatoire'),
      repository: Yup.string().nullable().label("repository").required('Le champ est obligatoire'),
      creation: Yup.string().nullable().label("creation").required('Le champ est obligatoire'),
      // genreres: Yup.
      // bach: Yup.
    });
    
    const identifieursValidator = Yup.object().shape({
      identifieurs: Yup.number().typeError('Le nombre d\'identifieurs doit être valide').label("procedure").min(1, 'Le nombre minimum d\'identifieurs est 1').max(4, 'Le nombre maximum d\'identifieurs est 4'),
      identifieur1: Yup.string().nullable().label("identifieur1").required('Le champ est obligatoire'),
      identifieur2: Yup.string().nullable().label("identifieur2").required('Le champ est obligatoire'),
      identifieur3: Yup.string().nullable().label("identifieur3").required('Le champ est obligatoire'),
      identifieur4: Yup.string().nullable().label("identifieur4").required('Le champ est obligatoire'),
      status1: Yup.string().nullable().label("identifieur1").required('Le champ est obligatoire'),
      status2: Yup.string().nullable().label("identifieur2").required('Le champ est obligatoire'),
      status3: Yup.string().nullable().label("identifieur3").required('Le champ est obligatoire'),
      status4: Yup.string().nullable().label("identifieur4").required('Le champ est obligatoire'),
      validation1: Yup.string().nullable().label("identifieur1").required('Le champ est obligatoire'),
      validation2: Yup.string().nullable().label("identifieur2").required('Le champ est obligatoire'),
      validation3: Yup.string().nullable().label("identifieur3").required('Le champ est obligatoire'),
      validation4: Yup.string().nullable().label("identifieur4").required('Le champ est obligatoire'),
      refus1: Yup.string().nullable().label("identifieur1").required('Le champ est obligatoire'),
      refus2: Yup.string().nullable().label("identifieur2").required('Le champ est obligatoire'),
      refus3: Yup.string().nullable().label("identifieur3").required('Le champ est obligatoire'),
      refus4: Yup.string().nullable().label("identifieur4").required('Le champ est obligatoire'),
    })

    const state = reactive({
      initialParams: {} as any,
      parametres: {} as any,
      paramStorage: {} as any,
      initParamStorage: {} as any,
      initialWorkflow: [] as any,
      workflow: [] as any
    })

    const sendParams = (type, datas, indicator, turn) => {
      setTimeout(() => {  
        mAxiosApi.post("setParametrage", datas)
        .then(async () => {
          Swal.fire({
            title: 'Succès',
            text: "Paramétrage effectué !",
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          });
          indicator.value?.setAttribute("data-kt-indicator", "off");
          if(window.localStorage.getItem('codeArk')){
            const parametrage = await getAxios("/getParametrage")
            store.dispatch(Actions.SET_PARAMETRAGE_ACTION, parametrage.results);
          }
          state.initialParams = {...datas};
          turn.value = false;
          if (type == 'all') {
            state.initialParams.par_n_genre_responsable = state.parametres.par_n_genre_responsable;
            state.initialParams.par_x_client_bach = state.parametres.par_x_client_bach;
          }
        })
        .catch((err) => {
          console.error(err);
        });
      }, 500);
    }

    const saveParams = () => {
      const datas = {...state.parametres};
      datas.par_n_identifieurs = state.initialParams.par_n_identifieurs
      datas.par_n_storage_s3 = state.initialParams.par_n_storage_s3
      datas.par_n_storage_local = state.initialParams.par_n_storage_local
      applychanges.value?.setAttribute("data-kt-indicator", "on");
      sendParams('all', datas, applychanges, changeDatas)
    };

    const saveStorage = () => {
      const datas = {...state.paramStorage};
      applystorage.value?.setAttribute("data-kt-indicator", "on");
      sendParams('all', datas, applystorage, changeStorage)
    }

    const saveIdentifieurs = () => {
      const status1 = state.workflow[0].status.toUpperCase();
      const status2 = state.workflow[1].status.toUpperCase();
      const status3 = state.workflow[2].status.toUpperCase();
      const status4 = state.workflow[3].status.toUpperCase();
      if(status1 == "TERMINÉ" || status2 == "TERMINÉ" || status3 == "TERMINÉ" || status4 == "TERMINÉ" ||
      status1 == "SUPPRIMÉ" || status2 == "SUPPRIMÉ" || status3 == "SUPPRIMÉ" || status4 == "SUPPRIMÉ" ||
      status1 == status2 || status1 == status3 || status1 == status4 || status2 == status3 || status2 == status4 || status3 == status4
      ){
        ElNotification({
          title: "Erreur",
          type: 'error',
          message: 'Un status ne peut ni être "Supprimé", "Terminé", ni être similaire à un autre'
        })
        return;
      }
      const datas = {
        newWorkflow: [...state.workflow],
        oldWorkflow: state.initialWorkflow,
        par_n_identifieurs: state.parametres.par_n_identifieurs
      };

      datas.newWorkflow[3].inactive = false;
      datas.newWorkflow[2].inactive = false;
      datas.newWorkflow[1].inactive = false;
      if(state.parametres.par_n_identifieurs < 4) datas.newWorkflow[3].inactive = true;
      if(state.parametres.par_n_identifieurs < 3) datas.newWorkflow[2].inactive = true;
      if(state.parametres.par_n_identifieurs < 2) datas.newWorkflow[1].inactive = true;

      applychangesidentifieurs.value?.setAttribute("data-kt-indicator", "on");
      setTimeout(() => {  
        mAxiosApi.post("setWorkflow", datas)
        .then(async (res) => {
          if(res.data.results == 'errorStatus'){
            ElNotification({
              title: "Erreur",
              type: 'error',
              message: 'Impossible de changer un identifieur car un ou plusieurs bordereaux ont le status d\'un identifieur supprimé'
            })
            applychangesidentifieurs.value?.setAttribute("data-kt-indicator", "off");
            return;
          }
          Swal.fire({
            title: 'Succès',
            text: "Paramétrage des identifieurs effectué !",
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          });
          applychangesidentifieurs.value?.setAttribute("data-kt-indicator", "off");
          if(window.localStorage.getItem('codeArk')){
            const parametrage = await getAxios("/getParametrage")
            store.dispatch(Actions.SET_PARAMETRAGE_ACTION, parametrage.results);
          }
          store.dispatch(Actions.SET_INCREMENT_REFRESH_BORDEREAU);
          state.initialWorkflow = JSON.parse(JSON.stringify(state.workflow));
          changeIdentifieurs.value = false;
        })
        .catch((err) => {
          console.error(err);
        });
      }, 500);
    }

    const cancelModifs = (cancel) => {
      if(cancel == 'datas'){
        changeDatas.value = !changeDatas.value;
        const keep_n_identifieurs = state.parametres.par_n_identifieurs
        state.parametres = {...state.initialParams}
        state.parametres.par_n_identifieurs = keep_n_identifieurs
      }
      if(cancel == 'identifieurs'){
        changeIdentifieurs.value = !changeIdentifieurs.value;
        state.parametres.par_n_identifieurs = state.initialParams.par_n_identifieurs
        state.workflow[0] = {...state.initialWorkflow[0]};
        state.workflow[1] = {...state.initialWorkflow[1]};
        state.workflow[2] = {...state.initialWorkflow[2]};
        state.workflow[3] = {...state.initialWorkflow[3]};
      }
      if (cancel == 'storage') {
        changeStorage.value = !changeStorage.value;
        state.paramStorage = {...state.initParamStorage}
      }
    }

    const importAAC = async() => {
      const jsonText = await navigator.clipboard.readText();
      try {
        let errorInJSON = false;
        try{
          let datas = JSON.parse(jsonText);
          datas = datas.service;
          console.log(datas.genreResponsable === 0)

          if(!datas || 
          !datas.prefixeIdIr ||
          !datas.codeService ||
          !datas.nomService ||
          !datas.codePays ||
          !datas.numDepart ||
          !datas.nomDepart ||
          !datas.nomVille ||
          !datas.nomRegion ||
          !datas.nomResponsable ||
          !datas.titreResponsable ||
          (!datas.nomMoteur && datas.nomMoteur !== 0) ||
          (!datas.genreResponsable && datas.genreResponsable !== 0)
          ) errorInJSON = true;

          if (!errorInJSON) {
            applychanges.value?.setAttribute("data-kt-indicator", "on");
            state.parametres.par_c_identifiant_service = datas.prefixeIdIr;
            state.parametres.par_c_code_service = datas.codeService;
            state.parametres.par_c_nom_service = datas.nomService;
            state.parametres.par_c_code_pays = datas.codePays;
            state.parametres.par_c_num_departement = datas.numDepart;
            state.parametres.par_c_nom_departement = datas.nomDepart;
            state.parametres.par_c_ville = datas.nomVille;
            state.parametres.par_c_region = datas.nomRegion;
            state.parametres.par_c_nom_responsable = datas.nomResponsable;
            state.parametres.par_c_titre_responsable = datas.titreResponsable;
            state.parametres.par_x_client_bach = datas.nomMoteur;
            state.parametres.par_n_genre_responsable = datas.genreResponsable;

            changeDatas.value = true;
          }

          } catch {
          errorInJSON = true;
        }
        if (errorInJSON) {
          ElNotification({
            type: 'error',
            title: 'Erreur',
            message: 'Le JSON dans le presse papier est invalide.'
          })
        }
      } catch (error) {
        ElNotification({
          type: 'error',
          title: 'Erreur',
          message: 'Veuillez autoriser la lecture du presse papier dans les paramètres du site.'
        })
      }
    }

    const selectGenre = [{label: "Madame", value: '1'}, {label: "Monsieur", value: '0'}];
    const selectBach = [{label: "Oui", value: '1'}, {label: "Non", value: '0'}];
    const selectS3 = [{label: "Oui", value: '1'}, {label: "Non", value: '0'}];
    const selectLocal = [{label: "Oui", value: '1'}, {label: "Non", value: '0'}];
    const selectForcePath = [{label: "Oui", value: '1'}, {label: "Non", value: '0'}];
    const selectSSL = [{label: "Oui", value: '1'}, {label: "Non", value: '0'}];

    const editableChamps = [
      {label: 'Préfixe de l\'identifiant de service d\'archives', fieldName: 'identifiant', placeholder: 'Veuillez saisir le préfix de l\'identifiant', dataName: 'par_c_identifiant_service'},
      {label: 'Code du service d\'archives', fieldName: 'codeserv', placeholder: 'Veuillez saisir le code du service', dataName: 'par_c_code_service'},
      {label: 'Nom du service d\'archives', fieldName: 'nameserv', placeholder: 'Veuillez saisir le nom du service', dataName: 'par_c_nom_service'},
      {label: 'Code pays', fieldName: 'pays', placeholder: 'Veuillez saisir le code de pays', dataName: 'par_c_code_pays'},
      {label: 'N° département', fieldName: 'numdepart', placeholder: 'Veuillez saisir le n° de département', dataName: 'par_c_num_departement'},
      {label: 'Département', fieldName: 'nomdepart', placeholder: 'Veuillez saisir le département', dataName: 'par_c_nom_departement'},
      {label: 'Ville', fieldName: 'ville', placeholder: 'Veuillez saisir la ville', dataName: 'par_c_ville'},
      {label: 'Région', fieldName: 'region', placeholder: 'Veuillez saisir la région', dataName: 'par_c_region'},
      {label: 'Nom du responsable', fieldName: 'nameres', placeholder: 'Veuillez saisir le nom du responsable', dataName: 'par_c_nom_responsable'},
      {label: 'Civilité du responsable', fieldName: 'genreres', placeholder: '', dataName: 'par_n_genre_responsable', options: selectGenre},
      {label: 'Titre du responsable', fieldName: 'titreres', placeholder: 'Veuillez saisir le titre du responsable', dataName: 'par_c_titre_responsable'},
      {label: 'Procédure réglementaire', fieldName: 'procedure', placeholder: '', dataName: 'par_c_procedure_reglementaire'},
      {label: 'Client Bach', fieldName: 'bach', placeholder: 'Veuillez saisir le titre du responsable', dataName: 'par_x_client_bach', options: selectBach},
      {label: 'Statut juridique', fieldName: 'juridique', placeholder: 'Veuillez saisir le statut juridique', dataName: 'par_c_status_juridique'},
      {label: 'Publisher', fieldName: 'publisher', placeholder: 'Veuillez saisir le publisher', dataName: 'par_c_publisher'},
      {label: 'Adressline', fieldName: 'adressline', placeholder: 'Veuillez saisir l\'adressline', dataName: 'par_c_adressline'},
      {label: 'Repository', fieldName: 'repository', placeholder: 'Veuillez saisir le repository', dataName: 'par_c_repository'},
      {label: 'Creation EAD', fieldName: 'creation', placeholder: 'Veuillez saisir text de creation EAD', dataName: 'par_c_creation_text'},
    ]

    const nonEditableChamps = [
      {label: 'Code ARK', fieldName: 'codeArk', placeholder: 'Aucun code ARK', dataName: 'par_n_codeark'},
      {label: 'Version des scripts', fieldName: 'version', placeholder: 'Aucune version', dataName: 'par_n_der_version'},
    ]

    const storageChamps = [
      {label: 'Sauvegarde locale', fieldName: 'localsave', placeholder: '', dataName: 'par_n_storage_local', options: selectLocal},
      {label: 'Sauvegarde s3', fieldName: 's3save', placeholder: '', dataName: 'par_n_storage_s3', options: selectS3},
      {label: 'Endpoint', fieldName: 'nameserv', placeholder: 'Veuillez saisir l\'endpoint', dataName: 'par_c_s3_endpoint'},
      {label: 'Region', fieldName: 'S3Region', placeholder: 'Veuillez la région', dataName: 'par_c_s3_region'},
      {label: 'Bucket', fieldName: 's3bucket', placeholder: 'Veuillez saisir le bucket', dataName: 'par_c_s3_bucket'},
      {label: 'Accesskey', fieldName: 'accesskey', placeholder: 'Veuillez saisir l\'accesskey', dataName: 'par_c_s3_accesskey'},
      {label: 'SecretKey', fieldName: 'secretkey', placeholder: 'Veuillez saisir la secretkey', dataName: 'par_c_s3_secretkey'},
      {label: 'SSL', fieldName: 'ssl', placeholder: '', dataName: 'par_n_s3_use_ssl', options: selectSSL},
      {label: 'Clés max', fieldName: 'maxkeys', placeholder: 'Veuillez saisir les clés max', dataName: 'par_n_s3_max_keys', type: 'number'},
      {label: 'Root path', fieldName: 'rootpath', placeholder: 'Veuillez saisir la racine', dataName: 'par_c_s3_root_path'},
      {label: 'Force path style', fieldName: 'forcepath', placeholder: '', dataName: 'par_n_s3_force_path_style', options: selectForcePath}
    ]

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Paramétrage de l'application", []);
      const response = await getAxios("/getParametrage");
      state.parametres = response.results;
      state.paramStorage = {
        ...state.parametres,
        par_n_storage_local: state.parametres.par_n_storage_local,
        par_n_storage_s3: state.parametres.par_n_storage_s3,
        par_c_s3_endpoint: state.parametres.par_c_s3_endpoint,
        par_c_s3_region: state.parametres.par_c_s3_region,
        par_c_s3_bucket: state.parametres.par_c_s3_bucket,
        par_c_s3_accesskey: state.parametres.par_c_s3_accesskey,
        par_c_s3_secretkey: state.parametres.par_c_s3_secretkey,
        par_n_s3_use_ssl: state.parametres.par_n_s3_use_ssl,
        par_n_s3_max_keys: state.parametres.par_n_s3_max_keys,
        par_c_s3_root_path: state.parametres.par_c_s3_root_path,
        par_n_s3_force_path_style: state.parametres.par_n_s3_force_path_style
      }
      state.workflow = [
        {id: 1, identifieur: state.parametres.wof_c_identifieur_1, status: state.parametres.wof_c_status_1, validation: state.parametres.wof_c_validation_action_1, refus: state.parametres.wof_c_refus_action_1},
        {id: 2, identifieur: state.parametres.wof_c_identifieur_2, status: state.parametres.wof_c_status_2, validation: state.parametres.wof_c_validation_action_2, refus: state.parametres.wof_c_refus_action_2},
        {id: 3, identifieur: state.parametres.wof_c_identifieur_3, status: state.parametres.wof_c_status_3, validation: state.parametres.wof_c_validation_action_3, refus: state.parametres.wof_c_refus_action_3},
        {id: 4, identifieur: state.parametres.wof_c_identifieur_4, status: state.parametres.wof_c_status_4, validation: state.parametres.wof_c_validation_action_4, refus: state.parametres.wof_c_refus_action_4}
      ]
      state.initialWorkflow = JSON.parse(JSON.stringify(state.workflow));
      state.parametres.par_n_der_version = parseInt(response.results.par_n_der_version)/100;
      state.initialParams = {...state.parametres};
      state.initParamStorage = {...state.paramStorage}
    });

    return {
      saveParams,
      saveIdentifieurs,
      detailsValidator,
      identifieursValidator,
      changeDatas,
      changeStorage,
      changeIdentifieurs,
      state,
      applychanges,
      applystorage,
      applychangesidentifieurs,
      cancelModifs,
      importAAC,
      editableChamps,
      nonEditableChamps,
      storageChamps,
      saveStorage
    };
  },
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
